import * as React from "react";
import Moment from "react-moment";
import moment from "moment-timezone";
import classnames from "classnames";
import {
    Table,
  } from "reactstrap";

  var explorerUrl = "https://xmrchain.net/block/"

  /*interface Props {
      time: number;
  }*/
  
  interface Blocks {
    Blocks: {
        Block: number;
        Hash: string;
        Status: string;
        Amount: string;
        Time: string;
        Effort: string;
        Date: string;
        "(UTC+1)": string;
    }[]
}
  
export const BlockTable = (blocks: Blocks) => {
    return (
        <Table>
            <thead>
                <tr>
                    <th>Block</th>
                    <th>Status</th>
                    <th>Amount</th>
                    <th>Effort</th>
                    <th>Found</th>
                </tr>
            </thead>
            <tbody>
            {
                blocks.Blocks.map((block: any, i: number) => {
                    var blockAmount = block.value;
                    blockAmount /= Math.pow(10, 12);
                    var blockEffort = block.shares / block.diff;
                    blockEffort /= Math.pow(10, -2);
                    if (block.Block === 0) {
                        return
                    }
                    if (i > 15) {
                        return
                    }
                    return (
                        <tr key={block.Hash}>
                            <td><a href={explorerUrl + block.height} rel="noreferrer" target="_blank">{block.height}</a></td>
                            <td>
                                {block.unlocked === false ?
                                    <span class="text-warning"><i className="fas fa-lock" title="Confirming"/></span>
                                    :
                                    block.valid === false ?
                                    <span class="text-danger"><i className="fas fa-skull" title="Orphaned"/></span>
                                    :
                                    <span class="text-success"><i className="fas fa-unlock" title="Confirmed"/></span>
                                }
                            </td>
                            <td>
                                {block.value === "" ?
                                    ""
                                    :
                                    <span>{blockAmount.toFixed(6)}{" XMR"}</span>
                                }
                            </td>
                            <td>
                                {blockEffort > 100 ?
                                    <span class="text-danger">{blockEffort.toFixed(2)}{"%"}</span>
                                    :
                                    <span class="text-success">{blockEffort.toFixed(2)}{"%"}</span>
                                }
                            </td>
                            <td><Moment local format="l h:mma">{moment(block.ts).format()}</Moment></td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}