/*!

=========================================================
* BLK Design System React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import React from "react";
import useSWR from "swr";
import axios from "axios";
import {apiUrl, abbreviateCash} from "../../variables/utils";
import {Amount, Amount2} from "../../variables/amount";
import {Hashrate} from "../../variables/hashrate";

const fetcher = (url: string) => axios.get(url).then(res => res.data)

export default function PageHeader() {

  //api
  const {data: poolStats, error: poolStatsError} = useSWR(apiUrl + "pool/stats", fetcher, {refreshInterval: 1000 * 60})
  const {data: moneroPrice, error: moneroPriceError, isLoading} = useSWR('https://api.coingecko.com/api/v3/coins/markets?vs_currency=USD&ids=monero&order=market_cap_desc&per_page=1&page=1&sparkline=false&price_change_percentage=1h', fetcher, {refreshInterval: 1000 * 60})

  if (isLoading) return <div className="skeleton" />;
  return (
    <div className="page-header header-filter">
      <div className="squares square1" />
      <div className="squares square2" />
      <div className="squares square3" />
      <div className="squares square4" />
      <div className="squares square5" />
      <div className="squares square6" />
      <div className="squares square7" />
      <Container>
        <div className="content-center brand">
          <h1 className="h1-seo">An XMR Nonprofit</h1>
          <h3 className="d-none d-sm-block">
            Fully Open Source & 100% Free
          </h3>
          <br />
          <Row className="row-grid justify-content-between">
              <Col className="mt-lg-12" md="12">
                <Row>
                  <Col className="px-2 py-2" lg="6" sm="12">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col md="4" xs="5">
                            <div className="icon-big text-center icon-warning">
                              <i className="fas fa-chart-line text-warning" />
                            </div>
                          </Col>
                          <Col md="8" xs="7">
                            <div className="numbers">
                              <CardTitle tag="p"><Hashrate alt="Pool Hashrate" hashrate={poolStats?.pool_statistics.hashRate}/></CardTitle>
                              <p />
                              <p className="card-category">Pool Hashrate</p>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="px-2 py-2" lg="6" sm="12">
                    <Card className="card-stats upper bg-default ml-4">
                      <CardBody>
                        <Row>
                          <Col md="4" xs="5">
                            <div className="icon-big text-center icon-warning">
                              <i className="fas fa-coins text-white" />
                            </div>
                          </Col>
                          <Col md="8" xs="7">
                            <div className="numbers">
                              <CardTitle tag="p">${abbreviateCash(moneroPrice[0]?.current_price * poolStats?.pool_statistics.totalBlocksFound * 0.6)}</CardTitle>
                              <p />
                              <p className="card-category">USD Paid</p>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col className="px-2 py-2" lg="6" sm="12">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col md="4" xs="5">
                            <div className="icon-big text-center icon-warning">
                              <i className="fas fa-cubes text-info" />
                            </div>
                          </Col>
                          <Col md="8" xs="7">
                            <div className="numbers">
                              <CardTitle tag="p"><Amount alt="Blocks Found by Pool" amount={poolStats?.pool_statistics.totalBlocksFound}/></CardTitle>
                              <p />
                              <p className="card-category">Blocks Found</p>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="px-2 py-2" lg="6" sm="12">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col md="4" xs="5">
                            <div className="icon-big text-center icon-warning">
                              <i className="fas fa-users text-success" />
                            </div>
                          </Col>
                          <Col md="8" xs="7">
                            <div className="numbers">
                              <CardTitle tag="p"><Amount alt="Accounts/Workers Connected to Pool" amount={poolStats?.pool_statistics.miners}/></CardTitle>
                              <p />
                              <p className="card-category">Active Miners</p>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          <p><br /><b>We're available over Tor @</b><br />monerod2jqtblreunnwtzqbjjj4slvsd3cjbhnzt2evwnry5u772l3qd.onion</p>
        </div>
      </Container>
    </div>
  );
}
