import * as React from "react";
import Moment from "react-moment";
import moment from "moment-timezone";

interface Props {
    title: string;
    time: number;
    alt: string;
}

export const Time = (props: Props) => {
    return (
        <div className="w-flex">
            {/*<span className="" title={props.alt}>{props.title}</span>*/}
            <span className=""><Moment local format="h:mma">{moment(props.time).format()}</Moment></span>
        </div>
    )
}
