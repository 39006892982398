/*!

=========================================================
* BLK Design System React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {useState, useEffect} from "react";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Modal,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  FormGroup,
  Form,
  CustomInput,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  UncontrolledTooltip
} from "reactstrap";
import Cookies from "js-cookie";
import useSWR from "swr";
import axios from "axios";
import * as copy from 'copy-to-clipboard';
import {apiUrl, minPay} from "../variables/utils";
import {Amount, Amount2} from "../variables/amount";
import {Hashrate} from "../variables/hashrate";
import {Price} from "../variables/price";
import {Time} from "../variables/time";
import {Balance} from "../variables/balance";
import {Workers} from "../variables/workers";
import {BlockTable} from "../variables/blocks";
import {PaymentTable} from "../variables/payments";
import {WorkerList} from "../variables/workerlist";
import {PaymentList} from "../variables/paymentlist";
import {BlockPaymentList} from "../variables/blockpaymentlist";
import DashChart from "../variables/dashchart";
import MinerChart from "../variables/minerchart";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";

//API
const fetcher = (url: string) => axios.get(url).then(res => res.data)

export default function DashboardPage() {
  //Integration
  const url = () => {
    if (typeof window === undefined) return null
    let coin;
    if (localStorage.getItem("currency") !== null) {
        coin = localStorage.getItem("currency")
    } else {
        coin = "USD"
    }
    return "https://api.coingecko.com/api/v3/coins/markets?vs_currency=" + coin + "&ids=monero&order=market_cap_desc&per_page=1&page=1&sparkline=false&price_change_percentage=1h"
  };

  let {data: moneroPrice, error: moneroPriceError, isLoading} = useSWR(url, fetcher, {refreshInterval: 1000 * 60})
  var address="8B6nMw5K64bKcejt17PfBsjMpANKuRsBU3FeStsru5fTZeCwVqQVebUfwjPeoM8WshiAg1a5x85RgYx2s3JzTRLsKdK1Q9C"

      //Network Stats
  const {data: netStats, error: netStatsError} = useSWR(apiUrl + "network/stats", fetcher, {refreshInterval: 1000 * 60})
      //Pool Stats
  const {data: poolStats, error: poolStatsError} = useSWR(apiUrl + "pool/stats", fetcher, {refreshInterval: 1000 * 60})
      //Pool Ports
  const {data: poolPorts, error: poolPortsError} = useSWR(apiUrl + "pool/ports", fetcher, {refreshInterval: 1000 * 60})
      //Pool Blocks
  const {data: poolBlocks, error: poolBlocksError} = useSWR(apiUrl + "pool/blocks", fetcher, {refreshInterval: 1000 * 60})
      //Pool Payments
  const {data: poolPayments, error: poolPaymentsError} = useSWR(apiUrl + "pool/payments", fetcher, {refreshInterval: 1000 * 60})
      //Miner Workers Hashrate
  const {data: workerStats, error: workerStatsError} = useSWR(apiUrl + "miner/" + Cookies.get("wallet") + "/stats/allworkers", fetcher, {refreshInterval: 1000 * 60})
      //Miner Stats
  const {data: minerStats, error: minerStatsError} = useSWR(apiUrl + "miner/" + Cookies.get("wallet") + "/stats", fetcher, {refreshInterval: 1000 * 60})
      //Miner Payments
  const {data: minerPayments, error: minerPaymentsError} = useSWR(apiUrl + "miner/" + Cookies.get("wallet") + "/payments", fetcher, {refreshInterval: 1000 * 60})
      //Miner Block Payments
  const {data: minerBlockPayments, error: minerBlockPaymentsError} = useSWR(apiUrl + "miner/" + Cookies.get("wallet") + "/block_payments", fetcher, {refreshInterval: 1000 * 60})
      ////Payment threshold and email enabled boolean for miner
  const {data: minerSettings, error: minerSettingsError} = useSWR(apiUrl + "user/" + Cookies.get("wallet"), fetcher, {refreshInterval: 1000 * 60})
      //Miner Workers
  const {data: workerNames, error: workerNamesError} = useSWR(apiUrl + "miner/" + Cookies.get("wallet") + "/identifiers", fetcher, {refreshInterval: 1000 * 60})
      //Miner Hashrate
  const {data: minerHash, error: minerHashError} = useSWR(apiUrl + "miner/" + Cookies.get("wallet") + "/chart/hashrate/allWorkers", fetcher, {refreshInterval: 1000 * 60})
      //Bonus Hashrate
  const {data: boostStats, error: boostStatsError} = useSWR("https://bonus-api.monerod.org/2/summary", fetcher, {refreshInterval: 1000 * 60})
  
  //Modals
  const [blkModal, setBlkModal] = React.useState(false);
  const [payModal, setPayModal] = React.useState(false);
  const [conModal, setConModal] = React.useState(false);
  const [apiModal, setApiModal] = React.useState(false);

  //Pills
  const [pills, setPills] = React.useState('1');


  //Error handling
  const [bError, setError] = useState("")
  const [bSuccess, setSuccess] = useState("")

  //Currency Select
  const currencies = [
    {value: "ARS", name: "Argentine Peso"},
    {value: "AUD", name: "Australian Dollar"},
    {value: "BDT", name: "Bangladeshi Taka"},
    {value: "BTC", name: "Bitcoin"},
    {value: "BRL", name: "Brazilian Real"},
    {value: "GBP", name: "British Pound Sterling"},
    {value: "BGN", name: "Bulgarian Lev"},
    {value: "CAD", name: "Canadian Dollar"},
    {value: "CZK", name: "Czech Republic Koruna"},
    {value: "CNY", name: "Chinese Yuan"},
    {value: "ETH", name: "Ethereum"},
    {value: "EUR", name: "Euro"},
    {value: "INR", name: "Indian Rupee"},
    {value: "IDR", name: "Indonesian Rupiah"},
    {value: "IRR", name: "Iranian Rial"},
    {value: "JPY", name: "Japanese Yen"},
    {value: "KES", name: "Kenyan Shilling"},
    {value: "MNT", name: "Mongolian Tugrik"},
    {value: "MYR", name: "Malaysian Ringgit"},
    {value: "MXN", name: "Mexican Peso"},
    {value: "NGN", name: "Nigerian Naira"},
    {value: "NOK", name: "Norwegian Krone"},
    {value: "PHP", name: "Philippine Peso"},
    {value: "PLN", name: "Polish Zloty"},
    {value: "RON", name: "Romanian Leu"},
    {value: "RUB", name: "Russian Ruble"},
    {value: "SGD", name: "Singapore Dollar"},
    {value: "ZAR", name: "South African Rand"},
    {value: "KRW", name: "South Korean Won"},
    {value: "SEK", name: "Swedish Krona"},
    {value: "TWD", name: "Taiwan Dollar"},
    {value: "TRY", name: "Turkish Lira"},
    {value: "THB", name: "Thai Baht"},
    {value: "USD", name: "United States Dollar"},
    {value: "VND", name: "Vietnamese Dong"},
]

const [currency, setCurrency] = useState(currencies[currencies.length - 2])
let handleCurrencyChange = (e) => {
  setCurrency(e.target.value)
  console.log(currency)
}
function saveCurrency() {
    localStorage.setItem("currency", currency)
    setSuccess("Currency Updated!")
    return
}

//Settings Email + Subscribe + Threshold
var paymentThreshold = minerSettings?.payout_threshold;
    paymentThreshold /= Math.pow(10, 12);

    const [amount, setAmount] = useState('')
    function saveAmount() {
        axios.post(apiUrl + 'user/updateThreshold', {'username': Cookies.get("wallet"), 'threshold': amount}).then(response => {console.log(response); setSuccess("Payment Threshold Updated!")}).catch(error => {
            if (error.response) {
                setError("Ensure you set an acceptable amount.")
                return
            }
		});
    }

    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')
    const [enabled, setEnabled] = useState(Boolean(minerSettings?.email_enabled))
    const onEnable = () => setEnabled(!enabled)
    function saveEmail() {
        axios.post(apiUrl + 'user/subscribeEmail', {'username': Cookies.get("wallet"), 'enabled': + enabled, 'from': from, 'to': to}).then(response => {console.log(response); setSuccess("Alert Preferences Updated!")}).catch(error => {
            if (error.response) {
                setError("Ensure your old email is correct.")
                return
            }
		});
  }

  //Design
  const [squares1to6, setSquares1to6] = React.useState("");
  const [squares7and8, setSquares7and8] = React.useState("");
  const [fullNameFocus, setFullNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.toggle("register-page");
    document.documentElement.addEventListener("mousemove", followCursor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("register-page");
      document.documentElement.removeEventListener("mousemove", followCursor);
    };
  }, []);
  const followCursor = (event) => {
    let posX = event.clientX - window.innerWidth / 2;
    let posY = event.clientY - window.innerWidth / 6;
    setSquares1to6(
      "perspective(500px) rotateY(" +
        posX * 0.05 +
        "deg) rotateX(" +
        posY * -0.05 +
        "deg)"
    );
    setSquares7and8(
      "perspective(500px) rotateY(" +
        posX * 0.02 +
        "deg) rotateX(" +
        posY * -0.02 +
        "deg)"
    );
  };

  if (isLoading) return <div className="skeleton" />;
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header">
          <div className="page-header-image" />
          <div className="content">
            <Container>
              <Row>
                <Col className="offset-lg-0 offset-md-3" lg="4" md="4">
                  <div
                    className="square square-7"
                    id="square7"
                    style={{ transform: squares7and8 }}
                  />
                  <div
                    className="square square-8"
                    id="square8"
                    style={{ transform: squares7and8 }}
                  />
                  <Card className="card-register">
                    <CardHeader>
                      <CardImg
                        alt="..."
                        src={require("assets/img/square-purple-1.png")}
                      />
                      <CardTitle tag="h4">Network</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row style={{marginBottom:10}}>
                        <Col className="offset-lg-0 offset-md-3" lg="6" md="6">
                          <UncontrolledTooltip delay={0} target="tooltip34764568">
                            Current XMR Price
                          </UncontrolledTooltip>
                          <span id="tooltip34764568" style={{fontSize:15}}><i className="fas fa-money-bill" /> Current Price</span><br />
                          <span style={{fontSize:20}}><Price title="Price" alt="Current XMR Price" price={moneroPrice[0]?.current_price}/></span>
                        </Col>
                        <Col className="offset-lg-0 offset-md-3" lg="6" md="6">
                          <UncontrolledTooltip delay={0} target="tooltip56348875">
                            Network Hashrate
                          </UncontrolledTooltip>
                          <span id="tooltip56348875" style={{fontSize:15}}><i className="fas fa-chart-line" /> Hashrate</span><br />
                          <span style={{fontSize:20}}><Hashrate title="Hashrate" alt="Network Hashrate" hashrate={netStats?.difficulty / 120}/></span>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="offset-lg-0 offset-md-3" lg="6" md="6">
                          <UncontrolledTooltip delay={0} target="tooltip5865347">
                            Network Block Height
                          </UncontrolledTooltip>
                          <span id="tooltip5865347" style={{fontSize:15}}><i className="fas fa-database" /> Block Height</span><br />
                          <span style={{fontSize:20}}><Amount title="Height" alt="Network Block Height" amount={netStats?.height}/></span>
                        </Col>
                        <Col className="offset-lg-0 offset-md-3" lg="6" md="6">
                          <UncontrolledTooltip delay={0} target="tooltip596457">
                            Network Last Block Time
                          </UncontrolledTooltip>
                          <span id="tooltip596457" style={{fontSize:15}}><i className="fas fa-clock" /> Last Block Time</span><br />
                          <span style={{fontSize:20}}><Time title="Time" alt="Network Last Block Time" time={netStats?.ts * 1000}/></span>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                    </CardFooter>
                  </Card>
                </Col>
                <Col className="offset-lg-0 offset-md-3" lg="8" md="8">
                  <Card className="card-register">
                    <CardHeader>
                      <div style={{height:110}}><DashChart style={{zIndex:2}} /></div>
                      <CardImg
                        alt="..."
                        style={{zIndex:3}}
                        src={require("assets/img/square-purple-1.png")}
                      />
                      <CardTitle tag="h4" style={{zIndex:4, marginTop:-110, width:300}}>Pool</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row style={{marginBottom:10}}>
                        <Col className="offset-lg-0 offset-md-3" lg="3" md="3">
                          <UncontrolledTooltip delay={0} target="tooltip5869346">
                            Pool Hashrate
                          </UncontrolledTooltip>
                          <span id="tooltip5869346" style={{fontSize:15}}><i className="fas fa-chart-line" /> Hashrate</span><br />
                          <span style={{fontSize:20}}><Hashrate title="Hashrate" alt="Pool Hashrate" hashrate={poolStats?.pool_statistics.hashRate}/></span>
                        </Col>
                        <Col className="offset-lg-0 offset-md-3" lg="3" md="3">
                          <UncontrolledTooltip delay={0} target="tooltip596347">
                            Pool Boost Hashrate for Active Miners
                          </UncontrolledTooltip>
                          <span id="tooltip596347" style={{fontSize:15}}><i className="fas fa-gauge-high" /> Boost Hashrate</span><br />
                          <span style={{fontSize:20}}><Hashrate title="Boost Hashrate from XMRig-md" alt="Pool Boost Hashrate for Active Miners" hashrate={boostStats?.hashrate.total[1]*1000}/></span>
                        </Col>
                        <Col className="offset-lg-0 offset-md-3" lg="3" md="3">
                          <UncontrolledTooltip delay={0} target="tooltip3488457">
                            Miners/Workers Connected to Pool
                          </UncontrolledTooltip>
                          <span id="tooltip3488457" style={{fontSize:15}}><i className="fas fa-users" /> Miners</span><br />
                          <span style={{fontSize:20}}><Amount2 title="Miners" alt="Accounts/Workers Connected to Pool" amount={poolStats?.pool_statistics.miners} amount2={poolPorts?.global[0]?.miners + poolPorts?.global[1].miners}/></span>
                        </Col>
                        <Col className="offset-lg-0 offset-md-3" lg="3" md="3">
                          <UncontrolledTooltip delay={0} target="tooltip89565374347">
                            Blocks Found by Pool
                          </UncontrolledTooltip>
                          <span id="tooltip89565374347" style={{fontSize:15}}><i className="fas fa-cubes" /> Blocks <a href="#" onClick={() => setBlkModal(true)}><i className="fas fa-circle-info" /></a></span><br />
                          <span style={{fontSize:20}}><Amount title="Blocks" alt="Blocks Found by Pool" amount={poolStats?.pool_statistics.totalBlocksFound}/></span>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="offset-lg-0 offset-md-3" lg="3" md="3">
                          <UncontrolledTooltip delay={0} target="tooltip9653547346">
                            Current Pool Block Effort
                          </UncontrolledTooltip>
                          <span id="tooltip9653547346" style={{fontSize:15}}><i className="fas fa-person-digging" /> Block Effort</span><br />
                          <span style={{fontSize:20}}><Amount title="Effort" alt="Current Pool Block Effort" amount={(100 * (poolStats?.pool_statistics.roundHashes / netStats?.difficulty)).toFixed(2) + "%"}/></span>
                        </Col>
                        <Col className="offset-lg-0 offset-md-3" lg="3" md="3">
                          <UncontrolledTooltip delay={0} target="tooltip4369898436">
                            Payments Made/Miners Paid by Pool
                          </UncontrolledTooltip>
                          <span id="tooltip4369898436" style={{fontSize:15}}><i className="fas fa-cash-register" /> Payments <a href="#" onClick={() => setPayModal(true)}><i className="fas fa-circle-info" /></a></span><br />
                          <span style={{fontSize:20}}><Amount2 title="Payments" alt="Payments Made/Accounts Paid by Pool" amount={poolStats?.pool_statistics.totalPayments} amount2={poolStats?.pool_statistics.totalMinersPaid}/></span>
                        </Col>
                        <Col className="offset-lg-0 offset-md-3" lg="3" md="3">
                          <UncontrolledTooltip delay={0} target="tooltip43968948">
                            Pool PPLNS Window Length
                          </UncontrolledTooltip>
                          <span id ="tooltip43968948" style={{fontSize:15}}><i className="fas fa-person-through-window" /> PPLNS Window</span><br />
                          <span style={{fontSize:20}}><Amount title="PPLNS Window" alt="Pool PPLNS Window Length" amount={(poolStats?.pool_statistics.pplnsWindowTime / 3600).toFixed(2) + " Hours"}/></span>
                        </Col>
                        <Col className="offset-lg-0 offset-md-3" lg="3" md="3">
                          <UncontrolledTooltip delay={0} target="tooltip4845547323324">
                            Pool Network Hashrate Share
                          </UncontrolledTooltip>
                          <span id="tooltip4845547323324" style={{fontSize:15}}><i className="fas fa-chart-pie" /> Network Share</span><br />
                          <span style={{fontSize:20}}><Amount title="Network Share" alt="Pool Network Hashrate Share" amount={(100 * (poolStats?.pool_statistics.hashRate / (netStats?.difficulty / 120))).toFixed(4) + "%"}/></span>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                    
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
              <Row>
              {Cookies.get("wallet") ?
                <>
                  <Col className="offset-lg-0 offset-md-3" lg="12" md="12">
                    <Card className="card-register">
                      <CardHeader>
                        <div style={{height:110}}><MinerChart style={{zIndex:2}} /></div>
                        <CardImg
                          alt="..."
                          style={{zIndex:3}}
                          src={require("assets/img/square-purple-1.png")}
                        />
                        <CardTitle tag="h4" style={{zIndex:4, marginTop:-110, width:300}}>Miner</CardTitle>
                        {workerNames == "MonerodBoost" ?
                          <>
                          <span style={{zIndex:5, position:'relative'}}><i className="fas fa-gauge-high" /> <b>Your hashrate is Boosting @ <Hashrate title="Boost Hashrate from XMRig-md" alt="Pool Boost Hashrate for Active Miners" hashrate={boostStats?.hashrate.total[1]*1000}/>!</b></span>
                          </>
                          :
                          <>
                          <span style={{zIndex:5, position:'relative'}}>Waiting for your turn to Boost...</span>
                          </>
                        }
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col className="offset-lg-0 offset-md-3" lg="3" md="3">
                            <Balance
                              balance={minerStats?.amtDue}
                              paid={minerStats?.amtPaid}
                              max={minerSettings?.payout_threshold}
                              fiat={moneroPrice[0]?.current_price}
                            />
                          </Col>
                          <Col className="offset-lg-0 offset-md-3" lg="9" md="9">
                            <Nav className="nav-pills-info nav-pills-icons" style={{/*marginTop:-30, paddingLeft:10*/}} pills>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    "active show": pills === '1'
                                  })}
                                  onClick={(e) => setPills('1')}
                                  href="#pablo"
                                >
                                  <i className="fas fa-user" /> Stats
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    "active show": pills === '2'
                                  })}
                                  onClick={(e) => setPills('2')}
                                  href="#pablo"
                                >
                                  <i className="fas fa-computer" /> Workers
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    "active show": pills === '3'
                                  })}
                                  onClick={(e) => setPills('3')}
                                  href="#pablo"
                                >
                                  <i className="fas fa-cash-register" /> Payments
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    "active show": pills === '4'
                                  })}
                                  onClick={(e) => setPills('4')}
                                  href="#pablo"
                                >
                                  <i className="fas fa-cube" /> <i className="fas fa-cash-register" /> Block Payments
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    "active show": pills === '5'
                                  })}
                                  onClick={(e) => setPills('5')}
                                  href="#pablo"
                                >
                                  <i className="fas fa-gear" /> Settings
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <TabContent activeTab={pills} style={{paddingLeft:10}}>
                              <TabPane tabId="1">
                                <Row style={{marginBottom:10, marginTop:10}}>
                                  <Col className="offset-lg-0 offset-md-3" lg="4" md="4">
                                    <UncontrolledTooltip delay={0} target="tooltip346983746">
                                      Your Current Total Effective Hashrate
                                    </UncontrolledTooltip>
                                    <span id="tooltip346983746" style={{fontSize:15}}><i className="fas fa-chart-line" /> Hashrate</span><br />
                                    <span style={{fontSize:20}}><Hashrate title="Hashrate" alt="Your Current Total Effective Hashrate" hashrate={minerStats?.hash}/></span>
                                  </Col>
                                  <Col className="offset-lg-0 offset-md-3" lg="4" md="4">
                                    <UncontrolledTooltip delay={0} target="tooltip3469809303">
                                      Your Current Total Effective Average Hashrate
                                    </UncontrolledTooltip>
                                    <span id="tooltip3469809303" style={{fontSize:15}}><i className="fas fa-percent" /> <i className="fas fa-chart-line" /> Average Hashrate</span><br />
                                    <span style={{fontSize:20}}><Hashrate title="Average Hashrate" alt="Your Current Total Effective Average Hashrate" hashrate={minerHash?.global?.map(e => e.hs).reduce((a, v) => { return a + v }) / minerHash?.global?.map(e => e.hs).length}/></span>
                                  </Col>
                                  <Col className="offset-lg-0 offset-md-3" lg="4" md="4">
                                    <UncontrolledTooltip delay={0} target="tooltip5497809348065">
                                      Your Estimated Earnings Per Block
                                    </UncontrolledTooltip>
                                    <span id="tooltip5497809348065" style={{fontSize:15}}><i className="fas fa-calculator" /> Estimated Earnings</span><br />
                                    <span style={{fontSize:20}}><Amount title="Estimated Earnings" alt="Your Estimated Earnings Per Block" amount={(100 * (minerStats?.hash / poolStats?.pool_statistics.hashRate) * 0.6 / 100).toFixed(6) + " XMR"}/></span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="offset-lg-0 offset-md-3" lg="4" md="4">
                                    <UncontrolledTooltip delay={0} target="tooltip895634968">
                                      Your Total Workers
                                    </UncontrolledTooltip>
                                    <span id="tooltip895634968" style={{fontSize:15}}><i className="fas fa-computer" /> Workers</span><br />
                                    <span style={{fontSize:20}}><Workers title="Workers" alt="Your Total Workers" workers={workerNames?.filter(e => e.identifer != 'global').length}/></span>
                                  </Col>
                                  <Col className="offset-lg-0 offset-md-3" lg="4" md="4">
                                    <UncontrolledTooltip delay={0} target="tooltip4369893486">
                                      Your Total Valid/Invalid Shares
                                    </UncontrolledTooltip>
                                    <span id="tooltip4369893486" style={{fontSize:15}}><i className="fas fa-list-check" /> Shares</span><br />
                                    <span style={{fontSize:20}}><Amount2 title="Shares" alt="Your Total Valid/Invalid Shares" amount={minerStats?.validShares} amount2={minerStats?.invalidShares}/></span>
                                  </Col>
                                  <Col className="offset-lg-0 offset-md-3" lg="4" md="4">
                                    <UncontrolledTooltip delay={0} target="tooltip469834698">
                                      Your Pool Hashrate Share
                                    </UncontrolledTooltip>
                                    <span id="tooltip469834698" style={{fontSize:15}}><i className="fas fa-chart-pie" /> Pool Share</span><br />
                                    <span style={{fontSize:20}}><Amount title="Pool Share" alt="Your Pool Hashrate Share" amount={(100 * (minerStats?.hash / poolStats?.pool_statistics.hashRate)).toFixed(4) + "%"}/></span>
                                  </Col>
                                </Row>
                              </TabPane>
                              <TabPane tabId="2">
                                <WorkerList Workers={Object.values(workerStats || {})}/>
                                <span className="text-muted">If you have over 5 workers, please consider using <a href="https://github.com/xmrig/xmrig-proxy" target="_blank" rel="noreferrer">XMRig-Proxy</a>. We will only display up to 10 workers here.</span>
                              </TabPane>
                              <TabPane tabId="3">
                                <PaymentList Payments={Object.values(minerPayments || {})}/>
                                <span className="text-muted">Your 10 most recent payments.</span>
                              </TabPane>
                              <TabPane tabId="4">
                                <BlockPaymentList Payments={Object.values(minerBlockPayments || {})}/>
                                <span className="text-muted">Your 10 most recent block payments (these records are purged periodically).</span>
                              </TabPane>
                              <TabPane tabId="5">
                                <Row>
                                  <Col className="offset-lg-0 offset-md-3" lg="4" md="4">
                                    {/*<span>Currency</span>
                                    <select onChange={handleCurrencyChange}> 
                                      <option value={currencies[currencies.length - 2]}> -- Select a Currency -- </option>
                                      {currencies.map((cur) => <option key={cur.name} value={cur.value}>{cur.name}</option>)}
                                    </select>
                                    <br />
                                    <Button color="primary" type="button"
                                        onClick={() => {saveCurrency()}}
                                    >
                                        Save
                                    </Button>
                                    <br /><br />*/}
                                    <span>Auto-Payment Threshold</span>
                                        <Input onChange={event => setAmount(event.target.value)} type="text" name="Amount" placeholder={"Current: " + paymentThreshold.toFixed(3) + " XMR (min: " + minPay + ")"} className=""/>
                                        <br />
                                        <Button color="primary" type="button"
                                            onClick={() => {saveAmount()}}
                                        >
                                            Save
                                        </Button>
                                      </Col>
                                      <Col className="offset-lg-0 offset-md-6" lg="8" md="8">
                                    <span>Email Alerts</span>
                                      <Input onChange={event => setFrom(event.target.value)} type="text" name="From" placeholder="FROM (only for changing email)" className=""/>
                                      <Input onChange={event => setTo(event.target.value)} type="text" name="To" placeholder="TO (or initial email setup)" className=""/>
                                      <br />
                                      <CustomInput type="switch" id="switch-alerts" label="Enable/Disable Email Alerts" checked={enabled} onChange={onEnable}/>
                                      <br />              
                                    <Button color="primary" type="button"
                                        onClick={() => {saveEmail()}}
                                    >
                                        Save
                                    </Button>
                                  </Col>
                                </Row>
                                <br />
                                {bError ? <div className="alert-danger text-center text-muted p-1"><b>{bError}</b></div> : ""}
                                {bSuccess ? <div className="alert-success text-center text-muted p-1"><b>{bSuccess}</b></div> : ""}
                              </TabPane>
                            </TabContent>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        
                      </CardFooter>
                    </Card>
                  </Col>
                </>
                :
                <>
                  <Col className="offset-lg-0 offset-md-3" lg="12" md="12">
                    <Card className="card-register">
                      <CardHeader>
                        <CardImg
                          alt="..."
                          src={require("assets/img/square-purple-1.png")}
                        />
                        <CardTitle tag="h4">Miner</CardTitle>
                      </CardHeader>
                      <CardBody>
                        Please sign in to view your stats.
                        <br />
                        <br />
                        <Button
                          className="nav-link d-none d-lg-block"
                          color="primary"
                          onClick={() => setConModal(true)}
                        >
                          <i className="fas fa-network-wired" /> Connection Guide
                        </Button>
                      </CardBody>
                      <CardFooter>
                        
                      </CardFooter>
                    </Card>
                  </Col>
                </>
                }
              </Row>
              <hr />
              <Row>
                <Col className="offset-lg-0 offset-md-3" lg="4" md="4">
                  <Card className="card-register">
                    <CardHeader>
                      <CardImg
                        alt="..."
                        src={require("assets/img/square-purple-1.png")}
                      />
                      <CardTitle tag="h4">FAQ</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <b>Is this pool really free?</b><br />
                      Yes! There are no fees on this pool, zero.<br /><br />
                      <b>How does the Boost work?</b><br />
                      Miners using XMRig-md contribute 1% of their hash directly back to the pool. This contribution cycles through all active miners, each for 30 minutes.<br /><br />
                      <b>I've been mining for a while but my pending payout hasn't increased?</b><br />
                      As long as you are properly connected to the pool and see your hashrate on the dashboard, you'll see your pending payment increase with each block found by the pool.<br /><br />
                      <b>Should I mine here or with P2Pool.io?</b><br />
                      If you have the resources and know-how, we always recommend P2Pool!<br />
                      <hr />
                      Monerod API Endpoints <a href="#" onClick={() => setApiModal(true)}><i className="fas fa-circle-info"/></a>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="offset-lg-0 offset-md-3" lg="4" md="4">
                  <Card className="card-register">
                    <CardHeader>
                      <CardImg
                        alt="..."
                        src={require("assets/img/square-purple-1.png")}
                      />
                      <CardTitle tag="h4">Node</CardTitle>
                    </CardHeader>
                    <CardBody>
                      Wallets, including the Official Monero Wallet, require a connection to the Monero blockchain. Makes sense. Our node allows folks to sync their wallets without having to download the entire blockchain. You can find the official guide <a href="https://www.getmonero.org/resources/user-guides/remote_node_gui.html" target="_blank" rel="noreferrer">here</a>. TLS (SSL) is required for our node, and some connection scenarios may require the <a href="https://letsencrypt.org/certificates/" target="_blank" rel="noreferrer">Let's Encrypt ISRG_Root_X1.pem</a> certificate.<br /><br />
                      <table>
                      <tr><th style={{paddingRight:20}}>Address</th><th style={{paddingRight:20}}>Port</th><th style={{paddingRight:20}}>TLS</th></tr>
                      <tr><td style={{paddingRight:20}}>node.monerod.org</td><td style={{paddingRight:20}}>443</td><td style={{paddingRight:20}}>Yes</td></tr>
                      </table>
                      <br />
                      <b className="text-danger">NOTE:</b><br />
                      It is strongly recommended that you host your own (local) copy of the blockchain. This is the only way to ensure nothing has been tampered with, with the added benefit of helping the network. We promise we're not malicious, but please only use our node if you cannot host your own copy.
                    </CardBody>
                  </Card>
                </Col>
                <Col className="offset-lg-0 offset-md-3" lg="4" md="4">
                  <Card className="card-register">
                    <CardHeader>
                      <CardImg
                        alt="..."
                        src={require("assets/img/square-purple-1.png")}
                      />
                      <CardTitle tag="h4">Donate</CardTitle>
                    </CardHeader>
                    <CardBody>
                      We don't need your hard-earned XMR, but it %100 helps us cover our cloud infra costs and community drops/giveaways!
                      <br />
                      <br />
                      <b>Simple Donation</b><br />
                      <i className="fas fa-clipboard text-success" title="Copy Address To Clipboard" onClick={() => copy(address)}/> Click the clipboard icon to copy the address, send what you please!<br />
                      <br />
                      <b>Discord-Based Donation</b><br />
                      Use the <span className="">$store</span> command in our Discord.<br />
                      <br />
                      <b>Pool Boost Hashrate Contribution</b><br />
                      Download and run <a href="https://github.com/Monerod-Project/xmrig-md/releases" target="_blank" rel="noreferrer" className="">XMRig-md</a>, default contribution of %1.<br />
                      <i>You can also point an entire worker at boost.monerod.org:6666 (TLS) or 7777 (Non-TLS) if you're extremely generous!</i>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {/* MODALS */}
              <Modal isOpen={blkModal} toggle={() => setBlkModal(false)}>
                  <div className="modal-header justify-content-center">
                    <button className="close" onClick={() => setBlkModal(false)}>
                      <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h4 className="title title-up">Recent Blocks Found</h4>
                  </div>
                  <div className="modal-body">
                    <BlockTable Blocks={Object.values(poolBlocks || {})}/>
                  </div>
                  <div className="modal-footer">
                    <Button
                      color="danger"
                      type="button"
                      onClick={() => setBlkModal(false)}
                    >
                      Close
                    </Button>
                  </div>
                </Modal>
                <Modal isOpen={payModal} toggle={() => setPayModal(false)}>
                  <div className="modal-header justify-content-center">
                    <button className="close" onClick={() => setPayModal(false)}>
                      <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h4 className="title title-up">Recent Payments Made</h4>
                  </div>
                  <div className="modal-body">
                    <PaymentTable Payments={Object.values(poolPayments || {})}/>
                  </div>
                  <div className="modal-footer">
                    <Button
                      color="danger"
                      type="button"
                      onClick={() => setPayModal(false)}
                    >
                      Close
                    </Button>
                  </div>
                </Modal>
                <Modal isOpen={conModal} toggle={() => setConModal(false)}>
                <div className="modal-header justify-content-center">
                  <button className="close" onClick={() => setConModal(false)}>
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                  <h4 className="title title-up">Mining Connection Guide</h4>
                </div>
                <div className="modal-body">
                  <b>1</b>. Download <a href="https://github.com/Monerod-Project/xmrig-md/releases" target="_blank" rel="noreferrer">XMRig-md</a> or <a href="https://github.com/xmrig/xmrig/releases" target="_blank" rel="noreferrer">XMRig Official</a>.<br />
                  <b>2</b>. Update the following values in XMRig's config.json file, then run XMRig as administrator (Windows) or sudo (Linux).<br /><br />
                  "url": "mine.monerod.org:4444",<br />
                  "user": "YourWalletAddress",<br />
                  "pass": "YourWorkerName",<br />
                  "keepalive": true,<br />
                  "tls": true,<br /><br />
                  <i>Unencrypted Mining:</i> Set your config.json's "url" to "mine.monerod.org:5555" with "tls" set to false.<br /><br />
                  <i>Tor Mining:</i> Set your config.json's "url" to <small>"monerod3ejgt6a54uq5gha3mt5qilwivrzp4genp2flhpudkjnhf33id.onion:4444"</small> with "tls" set to true.<br />
                  <b>3</b>. Sign In here with your Wallet Address!
                </div>
                <div className="modal-footer">
                  <Button
                    color="danger"
                    type="button"
                    onClick={() => setConModal(false)}
                  >
                    Close
                  </Button>
                </div>
              </Modal>
              <Modal isOpen={apiModal} toggle={() => setApiModal(false)}>
                <div className="modal-header justify-content-center">
                  <button className="close" onClick={() => setApiModal(false)}>
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                  <h4 className="title title-up">Public API Endpoints</h4>
                </div>
                <div className="modal-body">
                  <b>https://np-api.monerod.org/network/stats</b><br />
                  <i>Network stats</i><br /><br />
                  <b>https://np-api.monerod.org/pool/stats</b><br />
                  <i>Pool stats</i><br /><br />
                  <b>https://np-api.monerod.org/pool/blocks</b><br />
                  <i>Pool blocks</i><br /><br />
                  <b>https://np-api.monerod.org/pool/payments</b><br />
                  <i>Pool payments</i><br /><br />
                  <hr />
                  <b>https://np-api.monerod.org/miner/WALLET/stats</b><br />
                  <i>Miner stats</i><br /><br />
                  <b>https://np-api.monerod.org/miner/WALLET/stats/allworkers</b><br />
                  <i>Miner worker hashrates</i><br /><br />
                  <b>https://np-api.monerod.org/miner/WALLET/identifiers</b><br />
                  <i>Miner workers</i><br /><br />
                  <b>https://np-api.monerod.org/miner/WALLET/payments</b><br />
                  <i>Miner payments</i><br /><br />
                  <b>https://np-api.monerod.org/miner/WALLET/block_payments</b><br />
                  <i>Miner block payments</i><br /><br />
                </div>
                <div className="modal-footer">
                  <Button
                    color="danger"
                    type="button"
                    onClick={() => setApiModal(false)}
                  >
                    Close
                  </Button>
                </div>
              </Modal>
              <div className="register-bg" />
              <div
                className="square square-1"
                id="square1"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-2"
                id="square2"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-3"
                id="square3"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-4"
                id="square4"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-5"
                id="square5"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-6"
                id="square6"
                style={{ transform: squares1to6 }}
              />
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}