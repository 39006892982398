import * as React from "react";
import {abbreviateNumber} from "../variables/utils";
import Moment from "react-moment";
import moment from "moment-timezone";

interface Props {
    title: string;
    workers: number;
    alt: string;
}

export const Workers = (props: Props) => {
    return (
        <div className="w-flex">
            {/*<span className="text-gray-400 border-b border-purple-600" title={props.alt}>{props.title}</span>*/}
            <span className="block text-xl text-gray-100 font-black">{props.workers}</span>
        </div>
    )
}