/*!

=========================================================
* BLK Design System React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import {useState, useEffect} from "react";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

const moneroRegex = /^[4|8]{1}([A-Za-z0-9]{105}|[A-Za-z0-9]{94})$/

export default function IndexNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState("");
  const [color, setColor] = React.useState("navbar-transparent");
  React.useEffect(() => {
    window.addEventListener("scroll", changeColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);
  const changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      setColor("bg-info");
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      setColor("navbar-transparent");
    }
  };
  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };
  const scrollToDownload = () => {
    document
      .getElementById("download-section")
      .scrollIntoView({ behavior: "smooth" });
  };
  //Wallet
  const [wallet, setWallet] = useState("")

  useEffect(() => {
      const wallet = Cookies.get("wallet")
      setWallet(wallet ? wallet : "")
  }, []);

  const walletChange = (event: any) => {
    setWallet(event.target.value)
  }

  const signIn = () => {
    if (wallet === "") {
        setError("Please enter a valid address.")
        return
    }
    let addr = moneroRegex.test(wallet)
    if (addr === false) {
        setError("Sorry, that's not a valid address.")
        return
    }
    if (typeof window !== 'undefined') {
        Cookies.set("wallet", wallet, {sameSite: "strict", expires: 365 * 10})
    }
    document.location.href = '/dashboard'
  }
  //Modals
  const [formModal, setFormModal] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  {/*const [passwordFocus, setPasswordFocus] = React.useState(false);*/}

  //Error handling
  const [hError, setError] = useState("")
  const [hSuccess, setSuccess] = useState("")

  return (
    <Navbar className={"fixed-top " + color} color-on-scroll="100" expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand to="/" tag={Link} id="navbar-brand">
            The <span>Monerod</span> Project
          </NavbarBrand>
          <UncontrolledTooltip placement="bottom" target="navbar-brand">
            Design by Creative Tim<br />Integration by Monerod
          </UncontrolledTooltip>
          <button
            aria-expanded={collapseOpen}
            className="navbar-toggler navbar-toggler"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className={"justify-content-end " + collapseOut}
          navbar
          isOpen={collapseOpen}
          onExiting={onCollapseExiting}
          onExited={onCollapseExited}
        >
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Monerod
                </a>
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button
                  aria-expanded={collapseOpen}
                  className="navbar-toggler"
                  onClick={toggleCollapse}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>
            <UncontrolledTooltip delay={0} target="tooltip435233475">
              Follow us on Github
            </UncontrolledTooltip>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href="https://github.com/Monerod-Project/"
                rel="noopener noreferrer"
                target="_blank"
                id="tooltip435233475"
                /*title="Follow us on Github"*/
              >
                <i className="fab fa-github" />
                <p className="d-lg-none d-xl-none">Github</p>
              </NavLink>
            </NavItem>
            <UncontrolledTooltip delay={0} target="tooltip23429834900">
              Chat with us on Discord
            </UncontrolledTooltip>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href="https://discord.gg/ZypfV3kkb6"
                rel="noopener noreferrer"
                target="_blank"
                id="tooltip23429834900"
                /*title="Chat with us on Discord"*/
              >
                <i className="fab fa-discord" />
                <p className="d-lg-none d-xl-none">Discord</p>
              </NavLink>
            </NavItem>
            <UncontrolledTooltip delay={0} target="tooltip2543873990">
              Chat with us on Matrix
            </UncontrolledTooltip>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href="https://matrix.to/#/!pGfBoPkfnkVTZbEviZ:monero.social?via=monero.social"
                rel="noopener noreferrer"
                target="_blank"
                id="tooltip2543873990"
                /*title="Chat with us on Matrix"*/
              >
                <i className="fas fa-comment" />
                <p className="d-lg-none d-xl-none">Matrix</p>
              </NavLink>
            </NavItem>
            <UncontrolledTooltip delay={0} target="tooltip2348841145">
              Shoot us an Email
            </UncontrolledTooltip>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href="mailto:support@monerod.org"
                rel="noopener noreferrer"
                target="_blank"
                id="tooltip2348841145"
                /*title="Shoot us an Email"*/
              >
                <i className="fas fa-envelope" />
                <p className="d-lg-none d-xl-none">Email</p>
              </NavLink>
            </NavItem>
            <UncontrolledTooltip delay={0} target="tooltip23345874727327">
              View our Infastructure Status
            </UncontrolledTooltip>
            <NavItem className="p-0">
              <NavLink
                data-placement="bottom"
                href="https://stats.uptimerobot.com/Yq6qQF3K0o"
                rel="noopener noreferrer"
                target="_blank"
                id="tooltip23345874727327"
                /*title="View our Infastructure Status"*/
              >
                <i className="fas fa-server" />
                <p className="d-lg-none d-xl-none">Infra</p>
              </NavLink>
            </NavItem>
            {/*<UncontrolledDropdown nav>
              <DropdownToggle
                caret
                color="default"
                data-toggle="dropdown"
                href="#"
                nav
                onClick={(e) => e.preventDefault()}
              >
                <i className="fa fa-cogs d-lg-none d-xl-none" />
                Menu
              </DropdownToggle>
              <DropdownMenu className="dropdown-with-icons">
                <DropdownItem href="https://demos.creative-tim.com/blk-design-system-react/#/documentation/overview">
                  <i className="tim-icons icon-paper" />
                  Documentation
                </DropdownItem>
                <DropdownItem tag={Link} to="/register-page">
                  <i className="tim-icons icon-bullet-list-67" />
                  Register Page
                </DropdownItem>
                <DropdownItem tag={Link} to="/landing-page">
                  <i className="tim-icons icon-image-02" />
                  Landing Page
                </DropdownItem>
                <DropdownItem tag={Link} to="/profile-page">
                  <i className="tim-icons icon-single-02" />
                  Profile Page
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>*/}
            <NavItem>
              <Button
                className="nav-link d-none d-lg-block"
                color="primary"
                tag={Link}
                to="/dashboard"
              >
                <i className="tim-icons icon-spaceship" /> Dashboard
              </Button>
            </NavItem>
            {wallet !== "" ?
            <>
            <NavItem>
              <Button
                className="nav-link d-none d-lg-block"
                color="default"
                onClick={() => {Cookies.remove('wallet'); document.location.href='/'}}
              >
                <i className="fas fa-sign-out" /> Sign Out
              </Button>
            </NavItem>
            </>
            :
            <>
            <NavItem>
              <Button
                className="nav-link d-none d-lg-block"
                color="default"
                onClick={() => setFormModal(true)}
              >
                <i className="fas fa-sign-in" /> Sign In
              </Button>
            </NavItem>
            </>
            }
            <Modal
              modalClassName="modal-black"
              isOpen={formModal}
              toggle={() => setFormModal(false)}
            >
              <div className="modal-header justify-content-center">
                <button className="close" onClick={() => setFormModal(false)}>
                  <i className="tim-icons icon-simple-remove text-white" />
                </button>
                <div className="text-muted text-center ml-auto mr-auto">
                  <h3 className="mb-0">Sign In</h3>
                </div>
              </div>
              <div className="modal-body">
                <div className="btn-wrapper text-center">
                  Need a Wallet? Download the <a href="https://www.getmonero.org/downloads/" target="_blank">Official Monero Wallet</a>.<br />
                  <small>We recommend creating a subaddress just for mining.</small>
                </div>
                <div className="text-center text-muted mb-4 mt-3">
                  <small>Do not share this address with anyone, ever.</small>
                </div>
                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup
                      className={classnames("input-group-alternative", {
                        "input-group-focus": emailFocus
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-wallet" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        onChange={walletChange}
                        placeholder="Wallet Address"
                        type="email"
                        onFocus={(e) => setEmailFocus(true)}
                        onBlur={(e) => setEmailFocus(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  {/*<FormGroup>
                    <InputGroup
                      className={classnames("input-group-alternative", {
                        "input-group-focus": passwordFocus
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        onFocus={(e) => setPasswordFocus(true)}
                        onBlur={(e) => setPasswordFocus(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup check className="mt-3">
                    <Label check>
                      <Input defaultChecked type="checkbox" />
                      <span className="form-check-sign" />
                      Remember me!
                    </Label>
                    </FormGroup>*/}
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="button" onClick={signIn}>
                      Sign in
                    </Button>
                  </div>
                </Form>
              </div>
            </Modal>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}
