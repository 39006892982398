/*!

=========================================================
* BLK Design System React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

export default function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md="3">
            <h1 className="title">The<br /><b>Monerod</b><br />Project</h1>
            <span>Since 2021 - v2.0.1</span>
          </Col>
          <Col md="3">
            <Nav>
              <NavItem>
                <NavLink to="/" tag={Link}>
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/dashboard" tag={Link}>
                  Dashboard
                </NavLink>
              </NavItem>
              <NavItem>
                {/*<NavLink to="/dashboard/#about" tag={Link}>
                  Public Node
                </NavLink>*/}
              </NavItem>
              <NavItem>
                {/*<NavLink to="/dashboard/#about" tag={Link}>
                  Donate
              </NavLink>*/}
              </NavItem>
            </Nav>
          </Col>
          <Col md="3">
            <Nav>
              <NavItem>
                <NavLink href="https://old.reddit.com/r/MoneroMining/" rel="noopener noreferrer" target="_blank">
                  Forum
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://xmrchain.net/" rel="noopener noreferrer" target="_blank">
                  Blockchain Explorer
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://www.getmonero.org/" rel="noopener noreferrer" target="_blank">
                  Monero Official
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://p2pool.io/#pool" rel="noopener noreferrer" target="_blank">
                  P2Pool
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col md="3">
            <h3 className="title">Connect:</h3>
            <div className="btn-wrapper profile">
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="https://github.com/Monerod-Project/"
                id="tooltip622135962"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fab fa-github" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip622135962">
                Follow us on Github
              </UncontrolledTooltip>
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="https://discord.gg/ZypfV3kkb6"
                id="tooltip230450801"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fab fa-discord" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip230450801">
                Chat with us on Discord
              </UncontrolledTooltip>
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="https://matrix.to/#/!pGfBoPkfnkVTZbEviZ:monero.social?via=monero.social"
                id="tooltip318450378"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fas fa-comment" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip318450378">
                Chat with us on Matrix
              </UncontrolledTooltip>
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="mailto:support@monerod.org"
                id="tooltip496832854"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fas fa-envelope" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip496832854">
                Shoot us an Email
              </UncontrolledTooltip>
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="https://stats.uptimerobot.com/Yq6qQF3K0o"
                id="tooltip573861247"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fas fa-server" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip573861247">
                View our Infastructure Status
              </UncontrolledTooltip>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
