import * as React from "react";

interface Props {
    title: string;
    price: number;
    alt: string;
}

export const Price = (props: Props) => {
    return (
        <div className="w-flex">
            {/*<span className="" title={props.alt}>{props.title}</span>*/}
            <span className="">{props.price} {localStorage.getItem("currency") || "USD"}</span>
        </div>
    )
}