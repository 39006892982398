import * as React from "react";
import {abbreviateNumber} from "../variables/utils";

interface Props {
    title: string;
    hashrate: number;
    alt: string;
}

export const Hashrate = (props: Props) => {
    return (
        <div className="w-flex">
            {/*<span className="" title={props.alt}>{props.title}</span>*/}
            <span className="">{abbreviateNumber(props.hashrate)}</span>
        </div>
    )
}