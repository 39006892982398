import * as React from "react";
import Moment from "react-moment";
import moment from "moment-timezone";
import classnames from "classnames";
import {
    Table,
  } from "reactstrap";

var explorerUrl = "https://xmrchain.net/tx/"

/*interface Props {
    time: number;
}*/

interface Payments {
    Payments: {
        Payees: number;
        Amount: string;
        Fee: string;
        Hash: string;
        Time: string;
        Date: string;
        "(UTC+1)": string;
    }[]
}

export const PaymentTable = (payments: Payments) => {
    return (
        <Table>
            <thead className="divide-y divide-gray-100">
                <tr>
                    <th>Payees</th>
                    <th>Amount</th>
                    <th>Fee</th>
                    <th>TX</th>
                    <th>Paid</th>
                </tr>
            </thead>
            <tbody>
            {
                payments.Payments.map((payment: any, i: number) => {
                    var amountPaid = payment.value;
                    amountPaid /= Math.pow(10, 12);
                    var feePaid = payment.fee;
                    feePaid /= Math.pow(10, 12);
                    if (payment.Payment === 0) {
                        return
                    }
                    if (i > 15) {
                        return
                    }
                    return (
                        <tr key={payment.Hash}>
                            <td><span>{payment.payees}</span></td>
                            <td><span>{amountPaid.toFixed(6)}{" XMR"}</span></td>
                            <td><span>{feePaid.toFixed(6)}{" XMR"}</span></td>
                            <td><span><a href={explorerUrl + payment.hash} rel="noreferrer" target="_blank"><i className="fas fa-circle-info" /></a></span></td>
                            <td><span><Moment local format="l h:mma">{moment(payment.ts).format()}</Moment></span></td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}