import * as React from "react";
import Moment from "react-moment";
import moment from "moment-timezone";
import {
    Table,
  } from "reactstrap";

var explorerUrl = "https://xmrchain.net/tx/"

/*interface Props {
    time: number;
}*/

interface Payments {
    Payments: {
        Amount: string;
        Hash: string;
        Date: string;
    }[]
}

export const PaymentList = (payments: Payments) => {
    return (
        <Table>
            <thead>
                <tr>
                    <th>Amount</th>
                    <th>TX</th>
                    <th>Paid</th>
                </tr>
            </thead>
            <tbody>
            {
                payments.Payments.map((payment: any, i: number) => {
                    var amountPaid = payment.amount;
                    amountPaid /= Math.pow(10, 12);
                    if (payment.Payment === 0) {
                        return
                    }
                    if (i > 10) {
                        return
                    }
                    return (
                        <tr key={payment.txnHash}>
                            <td>{amountPaid.toFixed(6)}{" XMR"}</td>
                            <td><a href={explorerUrl + payment.txnHash} target="_blank"><i className="fas fa-circle-info" /></a></td>
                            <td><Moment local format="l h:mma">{moment(payment.ts * 1000).format()}</Moment></td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}