import * as React from "react";
import Moment from "react-moment";
import moment from "moment-timezone";
import {
    Table,
  } from "reactstrap";

  var explorerUrl = "https://xmrchain.net/block/"

  /*interface Props {
      time: number;
  }*/
  
  interface Payments {
      Payments: {
          Amount: string;
          Share: string;
          Hash: string;
          Time: string;
          Date: string;
      }[]
  }
  
  export const BlockPaymentList = (payments: Payments) => {
      return (
          <Table>
              <thead>
                  <tr>
                      <th>Amount</th>
                      <th>Share</th>
                      <th>Block</th>
                      <th>Paid</th>
                      <th>Found</th>
                  </tr>
              </thead>
              <tbody>
              {
                  payments.Payments.map((payment: any, i: number) => {
                      if (payment.Payment === 0) {
                          return
                      }
                      if (payment.value == 0) {
                          return
                      }
                      if (i > 10) {
                          return
                      }
                      return (
                          <tr key={payment.hash}>
                              <td>{payment.value.toFixed(6)}{" XMR"}</td>
                              <td>{payment.value_percent.toFixed(6)}{"%"}</td>
                              <td><a href={explorerUrl + payment.hash} target="_blank"><i className="fas fa-circle-info" /></a></td>
                              <td><Moment local format="l h:mma">{moment(payment.ts * 1000).format()}</Moment></td>
                              <td><Moment local format="l h:mma">{moment(payment.ts_found * 1000).format()}</Moment></td>
                          </tr>
                      )
                  })}
              </tbody>
          </Table>
      )
  }