import * as React from "react";
import {abbreviateNumber} from "../variables/utils";
import Moment from "react-moment";
import moment from "moment-timezone";
import {
    Table,
  } from "reactstrap";

interface Props {
    Workers: {
        identifier: string;
        hash: number;
        validShares: number;
        invalidShares: number;
        hashes: number;
        lts: number;
    }[]
}

export const WorkerList = (props: Props) => {
    return (
        <Table>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Hashrate</th>
                    <th>Shares</th>
                    <th>Hashes</th>
                    <th>Last Seen</th>
                </tr>
            </thead>
            <tbody>
                {props.Workers.map((worker: any, i: number) => {
                    if (worker.Worker === 0) {
                        return
                        <p>Please connect your workers to the pool, or give it a few minutes if you just started mining.</p>
                    }
                    if (worker.identifier === "global") {
                        return
                    }
                    if (i > 10) {
                        return
                    }
                    return (
                        <tr key={worker.identifer}>
                            <td>
                                {
                                worker.identifer === "global" ? "~" : ""
                                }
                                {
                                worker.identifer === "" || worker.identifer === "x" ? "Update XMRig Pass Field" : ""
                                }
                                {
                                worker.identifer === "MonerodBoost" ? <i className="fas fa-gauge-high" /> + " " + worker.identifer : worker.identifer
                                }
                            </td>
                            <td>{abbreviateNumber(worker.hash)}</td>
                            <td>{worker.validShares}/{worker.invalidShares}</td>
                            <td>{worker.totalHash}</td>
                            <td><Moment local format="l h:mma">{moment(worker.lts * 1000).format()}</Moment></td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}