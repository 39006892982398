import * as React from "react";
import {
    Progress,
} from "reactstrap";

interface Props {
    balance: number;
    paid: number;
    max: number;
    fiat: number;
}

export const Balance = (props: Props) => {
var amountDue = props.balance;
amountDue /= Math.pow(10, 12);

var amountPaid = props.paid;
amountPaid /= Math.pow(10, 12);

var paymentThreshold = props.max;
paymentThreshold /= Math.pow(10, 12);

var progressPercent = (amountDue / paymentThreshold * 100).toFixed(2);
    return (
        <div className="w-flex rounded-md">
            <div className="w-full">
                <div className="flex items-center justify-between" style={{marginBottom:10}}>
                    <div className="flex items-center static" style={{marginBottom:10}}>
                        <i className="fas fa-wallet" /> Balance
                    </div>
                    <div className="">
                        <span className="">Pending/Paid: {amountDue.toFixed(4)}/{amountPaid.toFixed(4)} XMR</span><br />
                        <span className="">Pending/Paid: {(props.fiat * amountDue).toFixed(2)}/{(props.fiat * amountPaid).toFixed(2)} {localStorage.getItem("currency") || "USD"}</span><br />
                        <span className="">Payment Threshold: {paymentThreshold} XMR</span><br />
                    </div>
                </div>
                <div className="progress-container progress-info">
                <span className="progress-badge"><i className="fas fa-recycle" /> Payout Progress: <span className="progress-value">{progressPercent}%</span></span>
                <p><Progress max={paymentThreshold} value={amountDue}></Progress></p>
                </div>
            </div>
        </div>
    )
}